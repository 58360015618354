import React from 'react';
import Container from 'container';
import Button from 'button';
import s from './simple-title.module.scss';

export default function SimpleTitlePP({ paragraphs, button_text, button_href, ...props }) {
  return (
    <Container className={s.root}>
      <div className={s.text} dangerouslySetInnerHTML={{ __html: paragraphs }} />
      {button_text && (
      <div className={s.buttonWrapper}>
        <Button className={s.button} href={button_href}>{button_text}</Button>
      </div>
)}
    </Container>
  );
}
