import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import s from './hero-pp.module.scss';

export default function HeroPrivacyPolicy({title, ...props}) {
  return (
    <Container className={s.root}>
      <div className={s.overlay} />
      <div className={s.imgWrapper}>
        <Img className={s.img} src="cxn-hero-1" alt="cxn-hero-1" />
      </div>
      <div className={s.content}>
        <Headline h1 dash center white className={s.title}>{title}</Headline>
      </div>
    </Container>
  );
}
