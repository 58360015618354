import React from 'react';
import SimpleHero from 'shared/simple-hero';
import SimpleText from 'shared/simple-text';
import LoadMoreGrid from 'shared/load-more-grid';
import Meta from 'meta';

export default function PrivacyPolicyPage({ pageContext, ...props }) {
  const { featuredList } = pageContext;
  const cards = featuredList
    .map(({ featured, slug }) => ({
      project_name: featured.title,
      services_provided: featured.services_provided,
      image: featured.large_image,
      href: slug,
      showcase: featured.showcase
    }))
    .filter(({ showcase }) => showcase)
    .sort(() => Math.random() - 0.5);
  return (
    <main>
      <Meta
        title="CXN Collective | Digital Collective in Miami, DC, Latin America, and More"
        description="Mark your company’s place in the modern world with top digital marketing, design, and web development teams cementing your online presence."
        canonical="https://cxncollective.com/terms-and-condititons/"
      />
      <SimpleHero title="PRIVACY POLICY" />
      <SimpleText
        paragraphs={
          `<p>
          This Privacy Policy is effective as of June 15, 2019.
          </p>
          <p>
          We respect your privacy rights and value your trust.
          </p>
          <p>
          This privacy notice ("Privacy Policy") applies to Connections Collective INcorporated. The terms "we," "us," or "our" means Connections Collective Incorporated. This website (the "Site"), including https://www.cxncollective.com and https://careers.cxncollective.com, and all other mediums through which you have accessed this agreement (via desktop or mobile) are collectively referred to as the "Services." This Privacy Policy describes how we collect, receive, use, store, share, transfer, and process information when you use our Services. By accessing or using our Services in the United States, you consent to the collection, transfer, manipulation, storage, disclosure and other uses of Your Information (collectively, "Use of Your Information") as described in this Privacy Policy. Please note this Privacy Policy is limited to https://www.cxncollective.com and https://careers.cxncollective.com.
          </p>
          <p>
          If you are accessing or using our Services in the United Kingdom or the European Economic Area ("EEA"), in addition to this Privacy Policy, please see the EEA/UK Addendum below.
          </p>

          <p>
          For purposes of this Privacy Policy, "Your Information" means information that identifies or is about you, which may be of a confidential nature and may include personal information. This Privacy Policy applies to our Use of Your Information because of using our Services, and does not apply to the following:
          <ul>
            <li>Information collected through mediums other than our Services; or</li>
            <li>Information collected by any person or entity other than us, even if related to our Services (such as a third-party website).</li>
          </ul>
          <h3>Information We Collect and Receive About You</h3>
          <p>
          We may receive of collect several types of information about you, such as your name, role details, contact details, personal characteristics, demographic information (such as zip code, occupation, and other job-related information), location information, and education and training information. This information is collected as described below.
          </p>
          <h3>Information You Provide Voluntarily</h3>
          <p>
          The following are situations in which you may voluntarily provide Your Information to us
          </p>
          <ul>
            <li>When you provide your information directly, such as by submitting a questions or request; </li>
            <li>When you fill in registration forms or surveys;</li>
            <li>
            When you submit job applications, inquire about potential employment, or make other submissions; and
            </li>
            <li>When you engage in electronic messaging with us.</li>
          </ul>
          <h3>Information We Collect Automatically</h3>
          <p>
          In addition to the information you submit through the Site, we may collect certain information using automated tools such as cookies and tags.
          </p>
          <p>
          A "cookie" is a small data file stored by your web browser on your device. Cookies may allow a website to recognize and authenticate a user’s device, record user preferences, customize a user’s experience on the Site, track the pages a user clicks on while visiting the website, and identify the website visited immediately beforehand. Most web browsers accept cookies by default, but you may be able to adjust your browser settings to remove or reject cookies. Please note that blocking cookies may affect the availability and functionality of our Site and other websites. Also, disabling cookies may invalidate opt outs that use cookies to recognize devices that have opted out.
          </p>
          </p>
          <p>
          A "tag" (also known as a pixel tag or web beacon) is a small block of code on a web page that allows websites to do things like read and place cookies. The types of information we collect automatically when you visit the Site may include language preferences, length and time of visits, and pages viewed, IP addresses, device identifiers, browser characteristics, operating system details, home server domain names, and referring URLs.
          </p>
          <p>
          We may combine certain automatically-collected information with other information we obtain about you, which may include data we obtain from third parties. Information about your use of this Site and other websites may be collected across time, devices, and websites for various purposes, including to associate different devices you use. This allows us to infer the presence of a common user or household behind multiple devices or browsers, for instance, and then link those browsers and devices into a device graph. We do so in order to deliver more relevant and/or retargeted content.
          </p>
          <p>
          Information Third Parties Provide
          </p>
          <p>
          We also may obtain information about you from third parties, such as social media platforms (i.e., Facebook, LinkedIn, Twitter or others), and combine that information with the information you provide to us directly or that we collect automatically through the Site. We may use this combined information to improve our marketing efforts or for fraud prevention purposes, and as described in greater detail below (please read the How We Use the Information We Obtain).
          </p>
          <h3>Data Analytics</h3>
          <p>
          We use certain web analytics services, including third-party services such as Google Analytics, to help us understand and analyze how visitors use the Site. We may receive reports based on the use of these technologies on both an individual and aggregated basis. These analytics services, including third-party services such as Google Analytics, also use this information to place advertisements for our products on other websites that you visit after your use of the Site. These advertisements may be based on the pages you visited during your use of the Site. For further information about how Google may use data it collects through the Site you may click here. You also may choose to install the Google Analytics Opt-Out Browser Add-on to opt out of Google Analytics data collection.
          </p>
          <h3>How We Use the Information We Obtain</h3>
          <ul>
          We may use the information we collect about you for various purposes, including to:
          <li>Personalize your online experience</li>
          <li>Improve the Site, our marketing, and other products or services we may offer;</li>
          <li>Monitor and analyze site traffic, trends, usage, and activities of visitors and users on the Site;</li>
          <li>Address problems with the Site or our businesses and for other administrative or internal purposes;</li>
          <li>Respond to emails, inquiries, comments, questions, or other requests for support; </li>
          <li>Contact you regarding your use of the Site or, in some cases, changes to our policies;</li>
          <li>Process and communicate with you about applications for employment;</li>
          <li>Communicate with you for a variety of other purposes, including to promote the Site, gather feedback, develop and market products or services, share opportunities or other information you may find to be of interest, or as otherwise described to you at the point of data collection; </li>
          <li>Verify the identity of a registered user of the Site or the authenticity of login information;Verify the identity of a registered user of the Site or the authenticity of login information;</li>
          <li> Comply with applicable laws, regulations, or legal processes as well as industry standards and our company policies; or</li>
          <li>
          Prevent, investigate, identify, or take any other action about suspected or actual fraudulent or illegal activity or any activity that violates our policies.
          </li>
          </ul>
          <h3>How We May Disclose Information We Obtain to Third Parties</h3>
          <ul>We will share your information with third parties only in the ways that are described in the Privacy Policy. In addition, we may permit our agents, vendors, consultants, and other service providers to access information obtained through the Site to carry out work on our behalf. We also may share your information:

          <li>To promote the Site and our business to third parties, including data analytics and employment and recruiting entities;</li>
          <li>For general business and administrative purposes;</li>
          <li>If we are required to do so by law, regulation, or legal process (such as in response to a subpoena or court order or similar government request for information);</li>
          <li>To respond to requests by government agencies, including law enforcement authorities;</li>
          <li>When we believe disclosure is necessary or appropriate to prevent physical harm or financial loss, or in connection with an investigation of suspected or actual illegal activity; </li>
          <li>To enforce our policies, or to protect legal or contractual rights, property, or safety; or</li>
          <li>With third parties, to investigate or address possible criminal or fraudulent activity</li>
          </ul>
          <p>In addition, we reserve the right to disclose any information we obtain through the Site in the event we sell or transfer all or a portion of our business or assets (e.g., further to a merger, reorganization, liquidation, or any other business transaction), including negotiations of such transactions. </p>
          <h3>Data Security</h3>
          <p>
          We strive to maintain appropriate administrative, technical, and physical safeguards that are designed to help protect personal information collected or received through the Site. Although we follow reasonable procedures to safeguard information transmission via the Internet is not completely secure.If you have questions about the security of your personal information, or if you have reason to believe that the personal information that we hold about you is no longer secure, please contact us immediately at <a href="mailto:info@cxncollective.com">info@cxncollective.com.</a>
          </p>
          <h3>Links to Other Websites</h3>
          <p>The Site may include links to third-party websites for your convenience and information. This Privacy Policy does not apply to those websites, which may have their own privacy policies or notices that you should review to understand how they may use or disclose your personal information. Connections Collective is not responsible for the content or privacy practices of any linked websites that we do not control. </p>
          <h3>Social Features</h3>
          <p>The Site may include features that are designed to permit interactions that you initiate between the Site and third-party websites or services, including third-party social networks (collectively, "Social Features"). For example, you may be able to "like" or "share" content from this Site on other websites or services, such as Facebook, Twitter, Instagram, Youtube, and LinkedIn.</p>
          <p>If you use Social Features on the Site, both Connections Collective and the third-party services that operate those Social Features may have access to certain information about you and your use of both this Site and theirs. The information we collect in connection with your use of Social Features, such as usage information, is subject to this Privacy Policy. The information collected and stored by the third parties remains subject to those third parties’ privacy practices, including whether the third parties continue to share information with us, the types of information shared, and your choices with regard to what is visible to others on those third-party websites or services. </p>
          <h3>Your Choices</h3>
          <ul>
          We strive to provide you with choices regarding the personal information you provide to us. Below are some mechanisms that provide you with control over certain information:
          <li>Email Offers from Us. You may unsubscribe from receiving promotional emails from our brands by following the instructions provided in those email communications. Please note that even if you opt out of receiving promotional communications from us, we may continue to send you non-promotional emails, such as communications regarding our ongoing relationship with you. </li>
          <li>Accessing and Correcting Personal Information. You may request that we correct, update, amend, or delete your information by sending us an email at info@cxncollective.com. In addition, you may update or delete information that you provided to us when you register for an account or that you uploaded when using the Service by logging into your account and following the prompts to update or delete such information.</li>
          <li>Online Behavioral Advertising. Certain third-party advertising networks that deliver behavioral advertising are members of either the Network Advertising Initiative ("NAI") or Digital Advertising Alliance ("DAA"). You can prevent NAI members companies from collecting preference data about you by clicking http://optout.networkadvertising.org and following NAI’s directions. You can prevent DAA member companies from collecting preference data about you by clicking https://youradchoices.com/appchoices and following the DAA’s directions. Note that if you opt out through the DAA, you will still receive advertising. In addition, if you opt out through the DAA and later delete your cookies, use a different browser, or buy a new computer, you will need to renew your opt out choice. If you reside in the European Union, please check the European Interactive Digital Advertising Alliance (EDAA)'s consumer opt-out page(http://youronlinechoices.eu)In the mobile environment, most mobile operating systems offer device-based opt-out choices that are transmitted to companies providing interest-based advertising. To set an opt-out preference for a mobile device identifier (such as Apple's IDFA or Android's GAID), visit the device manufacturer's current choice instructions pages, or read more about sending signals to limit ad tracking for your operating system here: http://www.networkadvertising.org/mobile-choices. Please Note that these settings must be performed on each device (including each web browser on each device) for which you wish to opt-out, and if you clear your cookies or if you use a different browser or device, you will need to renew your opt-out preferences.</li>
          <li>Do Not Track. Your web browser may have settings that allow you to transmit a "Do Not Track" signal when you visit various websites or use online services, which sends a signal to websites visited by the user about the user's browser DNT preference setting. Connections Collective does not currently commit to responding to browsers' DNT signals with respect to our websites, in part, because no common industry standard for DNT has been adopted, including no consistent standard of interpreting user intent. To learn more about "Do Not Track" signals, you may wish to visit http://www.allaboutdnt.com </li>
          </ul>
          <h3>Children’s Information</h3>
          <p>This Site is not intended for, nor targeted to children under the age of 13, and we do not knowingly request or collect personal information from any person under the age of 13 through the Site.</p>
          <p>If we learn that we have received information directly from a child who is under the age of 13, we will delete the information in accordance with applicable law.</p>
          <p>If you are a parent or guardian of such a child and become aware that your child has provided personal information to us, please contact us at info@cxncollective.com and we will take reasonable steps immediately to remove any such information. </p>
          <h3>SPECIAL NOTIFICATION FOR Florida RESIDENTS – YOUR Florida PRIVACY RIGHTS</h3>
          <p>Individuals who are residents of Florida and have provided their personal information to us may request information regarding our disclosures, if any, of their personal information to third parties for direct marketing purposes. Such requests must be submitted to us at info@cxncollective.com or in writing at:</p>
          <p>
          Connections Collective Incorporated<br/>
          1815 Purdy Avenue, <br/>
          Miami Beach, FL 33155<br/>
          Attn: Privacy Officer<br/>
          </p>
          <p>
          Florida privacy rights requests must include the reference “Request for Florida Privacy Information” on the subject line and in the body of the message and must include the email address or mailing address, as applicable, for us to send our response. This request may be made no more than once per calendar year. We reserve the right not to respond to requests submitted other than to the address specified above.
          </p>
          <h3>Information Retention</h3>
          <p>We will retain your information for as long as your account is active or as needed to provide you services. Alternatively, will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Notice.</p>
          <p>We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
          <h3>Operation of our Services in the United States</h3>
          <p>Our Services are operated in the United States. If you are located in the EEA, the UK, Canada, or elsewhere outside of the United States, please be aware that any information you provide to us will be transferred to the United States. </p>
          <h3>Changes to this Privacy Policy</h3>
          <p>We reserve the right to update, amend and/or change this Privacy Policy at any time in our sole discretion. Amendments will take effect immediately upon us posting the updated Privacy Policy on our Services. You are encouraged to revisit this Privacy Policy from time to time to review any changes that have been made. The date on which this Privacy Policy was last updated will be noted immediately above this Privacy Policy. Your continued access and use of our Services following the posting of any such changes shall automatically be deemed your acceptance of all changes. If we make any material changes to how we use the information we have collected from you,we will disclose this by means of a notice on this site prior to the change becoming effective. </p>
          <h3>EEA/UK Addendum</h3>
          <p>
          The following Addendum applies to users who are resident in the European Economic Area ("EEA") and the United Kingdom, the provisions of which shall prevail over conflicting provisions in this Privacy Policy for EEA/UK residents only.
          </p>
          <ol>
            <li>Data Controller<p>For the purposes of data protection law, the controller is Connections Collective Incorporated, of 1815 Purdy Avenue, Miami Beach, FL 33155</p></li>
            <li>Our Legal Basis for Processing<ul>Our legal basis for processing your personal data will typically be one of the following:
              <li>
                Consent
                <p>
                  You have actively consented for us to process data by completing a form on the site, or have indicated your consent with another affirmative act.
                </p>
                </li>
                <li>
                Legitimate Interest
                <p>
                Our Legitimate Interests in processing your personal information are:
                <ul>
                  <li>
                  To ensure our website meets the high standard of usability and security you would expect;
                  </li>
                  <li>
                  To help us identify areas for improvement;
                  </li>
                  <li>
                  To enable us to communicate effectively regarding services we offer; and
                  </li>
                  <li>
                  To enable us to process candidate applications, contacts, queries, or complaints.
                  </li>
                </ul>
                </p>
                </li>
                <li>
                Legal Obligation
                <p>
                It is necessary for us to process your personal data for us or another Connections Collective company to comply with our legal or regulatory obligations.
                </p>
                </li>
              </ul>
              </li>
              <li>
              Your Data Rights
              <p>
              In addition to your rights of access and correction (rectification) described in “Your Choices”, EEA and UK residents have certain other rights defined in the General Data Protection Regulation (GDPR).
              </p>
              <p>
              Consent Withdrawal - You have the right to withdraw your consent in relation to our processing of your personal data based on your consent.
              </p>
              <p>
             You can unsubscribe from our marketing emails at any time using our email preferences page, or by clicking on the “unsubscribe” link
              </p>
              <p>
              If located in the United Kingdom or European Union, you can opt out of EDAA members online advertising networks and behavioral advertising through the European Interactive Digital Advertising Alliance (EDAA)'s consumer opt-out page here (http://youronlinechoices.eu/).
              </p>
              <p>
              Erasure - You have the right to ask us to right to request deletion of your personal data in certain circumstances.
              </p>
              <p>
              Restriction of Processing - You have the right to request that we restrict the processing of your data in certain circumstances.
              </p>
              <p>
              Object to Processing - You have the right to object to the use or disclosure of your personal data.
              </p>
              <p>
              Data Portability - You have the right to request we transmit your personal data to you or another company in certain circumstances.
              </p>
              <p>
              We process all requests in accordance with the law, and there may be legal reasons why we cannot fulfil all requests.
              </p>
              <p>
              Please contact us info@cxncollective.com if you wish to make a request. We have one month to respond to you. You are not required to pay any charge for exercising your rights.
              </p>
              </li>
              <li>
              International Transfer
              <p>
              We operate in the United States and transfer, store and process personal data outside of the EEA/UK. By submitting your personal data, you agree to this transfer, storing and processing.
              </p>
              <p>
              We rely on approved data transfer mechanisms (for example, the EU Standard Contractual Clauses) to ensure that personal data you submit is adequately safeguarded.
              </p>
              <h4>EU-U.S. Privacy Shield</h4>
              <p>
              Connections Collective participates in and has certified its compliance with the EU-U.S. Privacy Shield Framework. Connections Collective is committed to subjecting all personal data received from European Union (EU) member countries and the United Kingdom, in reliance on the Privacy Shield Framework, to the Framework’s applicable Principles. To learn more about the Privacy Shield Framework, visit the U.S. Department of Commerce’s Privacy Shield List. [https://www.privacyshield.gov/list]
              </p>
              <p>
              We are responsible for the processing of personal data received, under the Privacy Shield Framework, and subsequent transfers to a third party acting as an agent on its behalf. We comply with the Privacy Shield Principles for all onward transfers of personal data from the EU and the UK, including the onward transfer liability provisions.
              </p>
              <p>
              With respect to personal data received or transferred pursuant to the Privacy Shield Framework, we are subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
              </p>
              <p>
              If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third-party dispute resolution provider (free of charge) at https://feedback-form.truste.com/watchdog/request.
              </p>
              <p>
              Under certain conditions, more fully described on the Privacy Shield website [https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint], you may be entitled to invoke binding arbitration when other dispute resolution procedures have been exhausted.
              </p>
              </li>
              <li>Cookies
                <p>
                As described above (“Information We Collect Automatically”) we use cookies to help us analyze, improve, secure, and market our website and services, and to support functionality which enhances your browsing experience.
                </p>
                <p>
                You can read more about how and why we use cookies on our cookies page.
                </p>
              </li>
              <li>
              Questions & Concerns
              <p>
              If you have any comments or questions, or are concerned about the way in which we have handled any privacy matter, you can contact us using the details below:
              </p>
              <p>
              Post: Data Protection Officer, <br />
          Connections Collective Incorporated, <br />
          1815 Purdy Avenue, Miami Beach, FL 33155<br />
              </p>
              <p>
              You can also contact us at info@cxncollective.com or in writing at:
              </p>
              <p>
              Connections Collective Incorporated <br />
          1815 Purdy Avenue <br />
          Miami Beach, FL 33155 <br />
          Attn: Privacy Officer <br />
              </p>
              <h4>
              Complaints (UK)
              </h4>
              <p>
              You also have the right to lodge a complaint about our processing of your personal information with the Information Commissioner's Office, which is the Supervisory Authority in the UK. Contact details are as follows: https://ico.org.uk/concerns/ or 0303 123 1113 or casework@ico.org.uk/
              </p>
              <h4>Cookies on our services</h4>
              <p>
              When you use our Services, you may receive cookies or other similar technologies such as pixel tags from us and the third parties that collect information on our Services. We use cookies to determine that we give you a high-quality experience on our Services. We also use cookies to show you advertising that is relevant to you.
              </p>
              <p>
              However, if you prefer, you can change your cookie settings. Some browsers have options that allow the visitor to control whether the browser will accept cookies, reject cookies, or notify the visitor each time a cookie is sent. You may elect to reject cookies by adjusting your settings, but doing so will limit the range of features available to you on our Services and other major websites that use cookies.
              </p>
              <p>
              Managing cookies Opens another site in a new window that may not meet accessibility guidelines.
              </p>
              <p>
              Our Services also occasionally use "local shared objects" (also known as "Flash cookies"). Like browser cookies, Flash cookies may be used for coordinating content delivery, website functionality, maintaining preferences,advertising, or analytics. Unlike browser cookies, "Flash cookies" are not stored in the browser. You may be able to manage these Flash cookies by visiting the Adobe website.
              </p>
              <p>
              Managing Flash cookies Link opens another site that may not meet accessibility guidelines.
              </p>
              <ol>
                <li>Essential cookies – These cookies enable you to use our Services. These cookies are essential to enable you to browse our Services and use certain features. Disabling them may prevent you from using certain parts of the Services. Without these cookies, services such as shopping activity and paying activity cannot be provided. These cookies also help keep our Services safe and secure.</li>
                <li>
                Preference cookies – These cookies store information such as your preferred country and language selection, login data and website preferences. Without these cookies, our Services may not be able to remember certain choices you've previously made (such as a saved country / language preference) or personalize your browsing experience by providing you with relevant information. These cookies can also be used to recognize your device so that you do not have to provide the same information more than once.
                </li>
                <li>
                Performance cookies – These cookies collect information about how you use our Services such as which pages you visit regularly. These cookies are used to provide you with a high-quality experience by doing things such as tracking page load, site response times, and error messages.
                </li>
                <li>
                Content / advertising cookies – These cookies gather information about your use of our Services so we may improve your experience and provide you with more relevant content and advertising. They are also used to gather feedback on customer satisfaction through surveys. They remember that you've visited our Services and help us understand usage of our Services. Some of these cookies are from third parties that collect information about users of our Services (as described below under "Information collected by third parties on our Services") in order to provide advertising (on our Services and elsewhere) based on users’ online activities (so-called "interest-based advertising") on our Services and elsewhere online. The third parties involved in interest-based advertising collect internet browsing information (e.g., websites visited, time of visit) across different websites and over time, and they may use the information they collect on our Services to provide you ads (from us and other companies) across the internet.
                </li>
              </ol>
              </li>
          </ol>`
        }
      />
      <LoadMoreGrid
        {...{
          cards,
          maxCards: 3
        }}
      />
    </main>
  );
}
